import { useEffect } from 'react'
import { useQsQueryParams } from 'hooks/useQueryParams'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { CRUISE_ROUTES, DEFAULT_CABIN_CATEGORY_TYPE } from 'constants/cruise'
import { useAppDispatch } from './reduxHooks'
import { get as getLocalStorage, set as setLocalStorage } from 'lib/storage/isomorphicLocalStorage'

export default function useCruiseSelectedCabinType() {
  const dispatch = useAppDispatch()
  const { search, pathname } = useLocation()
  const { cabinTypes: selectedTypeFromQuery } = useQsQueryParams<{ cabinTypes?: string }>()

  const isCruiseSearchPage = pathname.includes(CRUISE_ROUTES.SEARCH_PAGE)
  const selectedTypeFromLocalStorage = getLocalStorage('cruiseCabinType')
  const selectedCabinType = selectedTypeFromQuery || selectedTypeFromLocalStorage || DEFAULT_CABIN_CATEGORY_TYPE

  // Update local storage when the cabin type is only in the query
  useEffect(() => {
    if (
      isCruiseSearchPage &&
      selectedCabinType &&
      selectedTypeFromLocalStorage !== selectedCabinType
    ) {
      setLocalStorage('cruiseCabinType', selectedCabinType)
    }
  }, [dispatch, isCruiseSearchPage, selectedCabinType, selectedTypeFromLocalStorage])

  // Update the query when the cabin type is only in the local storage
  useEffect(() => {
    if (
      isCruiseSearchPage &&
      selectedCabinType &&
      selectedTypeFromQuery !== selectedCabinType
    ) {
      const nextSearch = setSearchParamValue(search, 'cabinTypes', selectedCabinType)
      dispatch(replace({ search: nextSearch }))
    }
  }, [dispatch, isCruiseSearchPage, search, selectedCabinType, selectedTypeFromQuery])

  return selectedCabinType
}
